import {injectable} from 'inversify';
import {action, computed, observable, runInAction} from 'mobx';

import {IServiceLiveBase} from '@/services/IServiceLiveBase';
import {isPromise} from '@/utilites/type-guards/Complex';
import {MaybePromise} from '@/utilites/utility-types';

@injectable()
export abstract class ServiceLiveBase implements IServiceLiveBase {
	protected readonly isServiceStartedInternal = observable.box<boolean>(false);

	protected abstract onStart(): MaybePromise<void>;
	protected abstract onStop(): MaybePromise<void>;

	@action
	private setServiceStarted(value: boolean): void {
		runInAction(() => {
			this.isServiceStartedInternal.set(value);
		});
	}

	public start(): MaybePromise<void> {
		if (!this.isServiceStartedInternal.get()) {
			const started = this.onStart();
			if (isPromise(started)) {
				started.then(() => this.setServiceStarted(true));
				return;
			}
			this.setServiceStarted(true);
		}
	}

	public stop(): MaybePromise<void> {
		if (this.isServiceStartedInternal.get()) {
			const stopped = this.onStop();
			if (isPromise(stopped)) {
				stopped.then(() => this.setServiceStarted(false));
				return;
			}
			this.setServiceStarted(false);
		}
	}

	@computed
	public get isServiceStarted(): boolean {
		return this.isServiceStartedInternal.get();
	}
}
