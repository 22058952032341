import {MakeCommonResponseInterface} from '@/services/http-adapter/interfaces';

export enum Feature {
	DomainSelfhostedDisabled = 'domain-selfhosted-disabled',
	JivoSiteLandings = 'jivosite-landings',
	JivoSiteVerify = 'jivosite-verify',
}

export interface GetMeSuccessResponse {
	features: Feature[];
	has_verified_phone: boolean;
	is_trial_available: boolean;
	type?: any;
}

export interface GetMeErrorResponse {}

export type TGetMeResponse = MakeCommonResponseInterface<GetMeSuccessResponse, GetMeErrorResponse>;
