import classNames from 'classnames';
import {ChangeEvent, cloneElement, ReactElement, RefObject} from 'react';

import {
	CheckboxSize,
	CheckboxTheme,
	CheckboxType,
	CheckboxValue,
} from '@/components/base/checkbox-or-radio/interfaces';

import {Checked} from './Checked';
import styles from './CheckedBase.module.css';
import {isChecked} from './utils/is-checked';
import {isIndeterminate} from './utils/is-indeterminate';

interface CheckedBaseProps {
	inputRef?: RefObject<HTMLInputElement>;
	children?: ReactElement;
	size?: CheckboxSize;
	theme?: CheckboxTheme;
	type: CheckboxType;
	id?: string;
	name?: string;
	value?: CheckboxValue;
	checked?: boolean;
	indeterminate?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	className?: string;
	onChange?(event: ChangeEvent<HTMLInputElement>): void;
}

export function CheckedBase({
	inputRef,
	type,
	id,
	name,
	value,
	checked: _checked,
	disabled,
	readOnly,
	indeterminate: _indeterminate,
	onChange,
	className = null,
	children,
	size,
	theme,
}: CheckedBaseProps) {
	const indeterminate = isIndeterminate({
		type,
		indeterminate: _indeterminate,
	});
	const checked = isChecked({
		checked: _checked,
		indeterminate: _indeterminate,
		type,
	});

	return (
		<Checked
			ref={inputRef}
			className={classNames(styles.root, className, {
				[styles.sizeDouble]: size === CheckboxSize.Double,
				[styles.themeOverlay]: theme === CheckboxTheme.Overlay,
				[styles.stateChecked]: checked,
				[styles.stateIndeterminate]: indeterminate,
				[styles.stateDisabled]: disabled === true || readOnly === true,
				[styles.typeCheckbox]: type === CheckboxType.Checkbox,
				[styles.typeRadio]: type === CheckboxType.Radio,
			})}
			type={type}
			value={value}
			checked={checked}
			disabled={disabled}
			readOnly={readOnly}
			id={id}
			name={name}
			onChange={onChange}
		>
			<div className={styles.replacement}>
				{children && indeterminate === false
					? cloneElement(children, {
							className: classNames(children.props.className, styles.replacementFigure),
					  })
					: null}
			</div>
		</Checked>
	);
}
