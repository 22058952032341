import {ChangeEvent, memo} from 'react';

import {
	CheckboxSize,
	CheckboxTheme,
	CheckboxType,
	CheckboxValue,
} from '@/components/base/checkbox-or-radio/interfaces';
import IconTick from '@/icons/icon-tick.svg';

import {CheckedBase} from './CheckedBase';

interface CheckboxProps {
	size?: CheckboxSize;
	theme?: CheckboxTheme;
	id?: string;
	name?: string;
	value?: CheckboxValue;
	checked?: boolean;
	indeterminate?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	className?: string;
	onChange?(event: ChangeEvent<HTMLInputElement>): void;
}

export const Checkbox = memo(function Checkbox({className, ...props}: CheckboxProps) {
	return (
		<CheckedBase {...props} type={CheckboxType.Checkbox} className={className}>
			<IconTick width={16} height={16} />
		</CheckedBase>
	);
});

Checkbox.displayName = 'Checkbox';
