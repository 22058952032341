import {useImperativeHandle, useRef} from 'react';

/**
 * Wrap external ref
 *
 * @param {React.MutableRefObject} ref
 * @returns {React.MutableRefObject<null>}
 */
export function useForwardRef(ref) {
	const inputRef = useRef(null);

	useImperativeHandle(ref, () => inputRef.current);

	return inputRef;
}
