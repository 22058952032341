import classNames from 'classnames';
import {
	ChangeEvent,
	cloneElement,
	forwardRef,
	isValidElement,
	ReactNode,
	useCallback,
	useEffect,
} from 'react';

import {CheckboxType, CheckboxValue} from '@/components/base/checkbox-or-radio/interfaces';
import {resolveIdAndName} from '@/components/helpers/resolve-id-and-name';
import {useForwardRef} from '@/hooks/use-forward-ref';

import styles from './Checked.module.css';
import {isChecked} from './utils/is-checked';
import {isIndeterminate} from './utils/is-indeterminate';

interface CheckedProps {
	type: CheckboxType;
	id?: string;
	name?: string;
	value?: CheckboxValue;
	checked?: boolean;
	indeterminate?: boolean;
	disabled?: boolean;
	readOnly?: boolean;
	className?: string;
	children?: ReactNode;
	'aria-labelledby'?: string;
	'aria-describedby'?: string;
	'aria-invalid'?: boolean;
	inputClassName?: string;
	/* Клонирует вычисленное состояние checked и indeterminate в children */
	cloneState?: boolean;
	onChange?(event: ChangeEvent<HTMLInputElement>): void;
}

export const Checked = forwardRef(function Checked(
	{
		type,
		id,
		name,
		value,
		checked: _checked,
		disabled = false,
		readOnly = false,
		indeterminate: _indeterminate = false,
		className,
		children,
		'aria-labelledby': ariaLabelledby,
		'aria-describedby': ariaDescribedby,
		'aria-invalid': ariaInvalid,
		inputClassName,
		cloneState,
		onChange,
	}: CheckedProps,
	ref,
) {
	const inputRef = useForwardRef(ref);

	const resolvedIdAndName = resolveIdAndName(id, name);
	const onChangeWithFocus = useCallback(
		function (event) {
			inputRef.current?.focus();

			onChange?.(event);
		},
		[inputRef, onChange],
	);
	const indeterminate = isIndeterminate({
		indeterminate: _indeterminate,
		type,
	});
	const checked = isChecked({
		checked: _checked,
		indeterminate: _indeterminate,
		type,
	});

	useEffect(
		function () {
			if (inputRef.current) {
				inputRef.current.indeterminate = indeterminate;
			}
		},
		[indeterminate, inputRef],
	);

	return (
		<div className={classNames(className, styles.root)}>
			<input
				onChange={onChangeWithFocus}
				className={classNames(inputClassName, styles.input)}
				type={type}
				value={value}
				checked={checked}
				ref={inputRef}
				disabled={disabled}
				readOnly={readOnly}
				aria-labelledby={ariaLabelledby}
				aria-describedby={ariaDescribedby}
				aria-invalid={ariaInvalid}
				{...resolvedIdAndName}
			/>

			{isValidElement(children) && cloneState
				? cloneElement(children as any, {
						checked,
						indeterminate,
						'aria-invalid': ariaInvalid,
						'aria-disabled': disabled,
				  })
				: children}
		</div>
	);
});

Checked.displayName = 'Checked';
