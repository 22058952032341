import axios, {AxiosInstance} from 'axios';
import qs from 'qs';

import {IHttpAdapterService} from '@/services/http-adapter/IHttpAdapterService';
import {IHttpAdapterRequestConfig, IHttpAdapterResponse} from '@/services/http-adapter/interfaces';
import {ServiceBase} from '@/services/ServiceBase';
import {getCSRFToken} from '@/utilites/auth/get-csrf-token';
import {Nullable} from '@/utilites/utility-types';

export class HttpAdapterService extends ServiceBase implements IHttpAdapterService {
	private axiosInstance: Nullable<AxiosInstance> = null;

	private _createAxiosInstanceIfNeeded() {
		const headers = {
			'Cache-Control': 'no-cache',
			Pragma: 'no-cache',
			Expires: '0',
			'MRG-BIZ-CRM-To': 'enterprise-request',
		};

		if (document) {
			headers['X-CSRFToken'] = getCSRFToken();
		}

		if (!this.axiosInstance) {
			this.axiosInstance = axios.create({
				headers,
				validateStatus() {
					return true;
				},
				paramsSerializer(params) {
					return qs.stringify(params);
				},
			});
		}
	}

	private static _handleUnexpectedError(error: any) {
		console.error(error);
		// todo: добавить логирование ошибок
	}

	public async post<TD, TR = any>(
		url: string,
		data?: TD,
		config?: IHttpAdapterRequestConfig,
	): Promise<IHttpAdapterResponse<TR>> {
		try {
			this._createAxiosInstanceIfNeeded();
			return (await this.axiosInstance.post<TD, TR>(url, data, {...config})) as unknown as Promise<
				IHttpAdapterResponse<TR>
			>;
		} catch (error) {
			HttpAdapterService._handleUnexpectedError(error);
		}
	}

	public async get<TR = unknown>(
		url: string,
		config?: IHttpAdapterRequestConfig,
	): Promise<IHttpAdapterResponse<TR>> {
		try {
			this._createAxiosInstanceIfNeeded();
			return this.axiosInstance.get(url, {...config});
		} catch (error) {
			HttpAdapterService._handleUnexpectedError(error);
		}
	}
}
