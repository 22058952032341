import classNames from 'classnames';
import {cloneElement, ReactElement, ReactNode} from 'react';

import {isString} from '@/utilites/type-guards/Primitives';

import styles from './Block.module.css';

enum BlockType {
	Checkbox = 'checkbox',
	Switch = 'switch',
}

interface BlockProps {
	children: ReactElement;
	label: ReactElement;
	readOnly?: boolean;
	type?: BlockType;
	className?: string;
	errorMessage?: ReactNode;
}

export function Block({
	children,
	label,
	readOnly = false,
	type = BlockType.Checkbox,
	className,
	errorMessage,
}: BlockProps) {
	return (
		<div className={styles.root}>
			<div
				className={classNames(styles.inner, className, {
					[styles.stateDisabled]: readOnly,
				})}
			>
				{!!children &&
					cloneElement(children, {
						className: classNames(children.props.className, styles.input, {
							[styles.typeCheckbox]: type === BlockType.Checkbox,
							[styles.typeSwitch]: type === BlockType.Switch,
						}),
					})}

				{label}
			</div>
			{isString(errorMessage) ? (
				<div className={styles.errorMessage}>{errorMessage}</div>
			) : (
				errorMessage
			)}
		</div>
	);
}
