/* eslint-disable max-lines-per-function */
import {action, computed, makeObservable, observable} from 'mobx';
import {NextRouter} from 'next/router';

import {errorTextReplacer} from '@/components/common/forms/create-project-form/error-text-replacer';
import {DomainType} from '@/components/common/forms/create-project-form/interfaces';
import {injectProperty} from '@/container/injections';
import {IServiceFactory} from '@/factories/service-factory';
import {FormViewModelBase} from '@/mobx/auxiliary-models/form-base/FormViewModelBase';
import {SoonDeletedDomainCollection} from '@/mobx/business-collections/domain/SoonDeletedDomainCollection';
import {IApiService} from '@/services/api/IApiService';
import {AddDomainPostData} from '@/services/api/interfaces/add-domain';
import {HttpAdapterResponseExtractor} from '@/services/http-adapter/HttpAdapterResponseExtractor';
import {
	GA4EventId,
	MetricsCategory,
	TMRReachGoalId,
	UAEventType,
	XrayEventId,
	YAReachGoalId,
} from '@/services/metrics/constants';
import {IMetricsService} from '@/services/metrics/IMetricsService';
import {BLACK_COLOR, getGlobalABColor} from '@/utilites/get-global-ab-color';
import {getPropertyName} from '@/utilites/get-property-name';
import {isDefined} from '@/utilites/type-guards/Primitives';

interface CreateProjectFormViewModelContext {
	router: NextRouter;
	domainCollection: SoonDeletedDomainCollection;
}

export class CreateProjectFormViewModel extends FormViewModelBase {
	@injectProperty(IServiceFactory) protected readonly serviceFor!: IServiceFactory;

	// todo: инкапсулировать контекст и конструктор в базовый класс

	protected static context: CreateProjectFormViewModelContext;

	constructor(context: CreateProjectFormViewModelContext) {
		super();
		CreateProjectFormViewModel.context = context;
		makeObservable(this);
	}

	@observable
	public inProgress = false;

	@observable
	public domainType = DomainType.OwnDomain;

	@observable
	public ownDomainName = '';

	@observable
	public bizDomainName = '';

	@observable
	public agreeWithPolicy = false;

	@action
	public setDomainType(value: DomainType): void {
		this.domainType = value;
	}

	@action
	public setOwnDomainName(value: string): void {
		this.ownDomainName = value;
	}

	@action
	public setBizDomainName(value: string): void {
		this.bizDomainName = value;
	}

	@action
	public setAgreeWithPolicy(value: boolean): void {
		this.agreeWithPolicy = value;
	}

	public checkIfDomainInputDisabled(domainType: DomainType): boolean {
		return this.domainType !== domainType;
	}

	public checkIfDomainTypeSelected(domainType: DomainType): boolean {
		return this.domainType === domainType;
	}

	public async checkIsTrialAvailable(): Promise<boolean> {
		const apiService = this.serviceFor(IApiService);
		const response = await apiService.getMe();
		const responseExtractor = new HttpAdapterResponseExtractor(response);
		if (responseExtractor.isSuccessResponse) {
			const data = responseExtractor.getResponseData();
			return data.is_trial_available;
		}
		const errorMessage = responseExtractor.getErrorMessage();
		const error = new Error(errorMessage);
		console.error(error);
	}

	@computed
	public get totalDomainName(): string {
		return this.domainType === DomainType.BizDomain
			? this.bizDomainName.trim()
			: this.ownDomainName.trim();
	}

	@computed
	public get isFormFilled(): boolean {
		return this.agreeWithPolicy && this.totalDomainName !== '';
	}

	@action
	// eslint-disable-next-line sonarjs/cognitive-complexity
	public async submitFormData() {
		const apiService = this.serviceFor(IApiService);
		const metricsService = this.serviceFor(IMetricsService);
		const {domainCollection} = CreateProjectFormViewModel.context;
		const data = this.getFormData();
		try {
			this.inProgress = true;

			// NOTE: если такой домен уже создан, но не подтвержден, отправляем пользователя на verify
			await domainCollection.load();
			const domain = domainCollection.getByName(data.name);
			if (isDefined(domain) && !domain.confirmed) {
				await this.goToVerify(metricsService, true);
				return;
			}

			const isOwnDomain = this.domainType === DomainType.OwnDomain;

			// NOTE: если домена еще нет, пытаемся добавить
			const response = isOwnDomain
				? await apiService.addOwnDomain(data)
				: await apiService.addBizDomain(data);

			// <-- AB-testing BIZ-11633 -->
			const abColor = getGlobalABColor();
			const isBlack = abColor === BLACK_COLOR;
			// <!-- AB-testing BIZ-11633 -->

			const responseExtractor = new HttpAdapterResponseExtractor(response);
			if (responseExtractor.isSuccessResponse) {
				this.setSuccessFlag();

				if (!isOwnDomain) {
					metricsService.sendEvent({
						yaGoals: [YAReachGoalId.PddConnectedSuccessFirst, YAReachGoalId.PddConnectedSuccess],
						ga4Events: [GA4EventId.VerifySuccessFirst],
						uaEvents: [
							{
								type: UAEventType.MainEvent,
								payload: {
									category: MetricsCategory.Verify,
									action: 'verify_success_first',
								},
							},
							{
								type: UAEventType.MainEvent,
								payload: {
									category: MetricsCategory.Verify,
									action: 'verify_success',
								},
							},
						],
					});

					// <-- AB-testing BIZ-11633 -->
					if (abColor) {
						metricsService.sendEvent({
							yaGoals: [
								isBlack
									? YAReachGoalId.PddConnectedSuccessFirstBlack
									: YAReachGoalId.PddConnectedSuccessFirstWhite,
							],
							uaEvents: [
								{
									type: UAEventType.MainEvent,
									payload: {
										category: MetricsCategory.Verify,
										action: isBlack
											? 'verify_success_first_black'
											: 'verify_success_first_white',
									},
								},
							],
						});
					}
					// <!-- AB-testing BIZ-11633 -->
				}

				// <-- AB-testing BIZ-11633 -->
				if (abColor) {
					metricsService.sendEvent({
						yaGoals: [
							isBlack
								? YAReachGoalId.DomainAddSuccessBlack
								: YAReachGoalId.DomainAddSuccessWhite,
						],
						uaEvents: [
							{
								type: UAEventType.MainEvent,
								payload: {
									category: MetricsCategory.AddDomain,
									action: isBlack ? 'response_black' : 'response_white',
									label: 'Success',
								},
							},
						],
					});
				}
				// <!-- AB-testing BIZ-11633 -->

				metricsService.sendEvent({
					yaGoals: [YAReachGoalId.DomainAddSuccess],
					ga4Events: [GA4EventId.Response],
					tmrGoals: [TMRReachGoalId.DomainAddSuccess],
					uaEvents: [
						{
							type: UAEventType.MainEvent,
							payload: {
								category: MetricsCategory.AddDomain,
								action: 'response',
								label: 'Success',
							},
						},
					],
				});

				await this.goToVerify(metricsService, true);
				return;
			}

			const messagePropName = getPropertyName(() => data.name);

			const errorMessage = responseExtractor.getErrorMessage(messagePropName);
			const propName =
				this.domainType === DomainType.OwnDomain
					? getPropertyName(() => this.ownDomainName)
					: getPropertyName(() => this.bizDomainName);
			this.setValidationResult(propName, [false, errorTextReplacer(errorMessage)]);

			return response;
		} finally {
			this.inProgress = false;
		}
	}

	public getFormData(): AddDomainPostData {
		return {
			domain_version: 2,
			name: this.totalDomainName,
		};
	}

	public setSuccessFlag() {
		try {
			window.localStorage.setItem('domain-created', '1');
		} catch (error) {}
	}

	private async goToVerify(metricsService: IMetricsService, defined: boolean) {
		const {router} = CreateProjectFormViewModel.context;

		metricsService.sendEvent({
			xrayEvents: [
				{
					name: XrayEventId.showPageConfirmDomain,
					params: {
						domain: this.totalDomainName,
						defined,
					},
				},
			],
		});

		await router.push(`/verify/${this.totalDomainName}`);
	}
}
