export enum CheckboxType {
	Checkbox = 'checkbox',
	Radio = 'radio',
}

export type CheckboxValue = string | number;

export enum CheckboxSize {
	Double = 'double',
}

export enum CheckboxTheme {
	Overlay = 'overlay',
}
