import classNames from 'classnames';
import {
	ChangeEventHandler,
	FocusEventHandler,
	forwardRef,
	HTMLInputTypeAttribute,
	PropsWithChildren,
	Ref,
	useCallback,
	useEffect,
	useRef,
} from 'react';

import {isCallable} from '@/utilites/type-guards/Complex';
import {isNull} from '@/utilites/type-guards/Primitives';

import {resolveIdAndName} from '../../helpers/resolve-id-and-name';
import styles from './Input.module.css';
import {AutocompleteType, INPUT_STYLES, inputModeType} from './types';

export interface InputProps {
	elRef?: Ref<HTMLInputElement>;
	autocomplete?: AutocompleteType;
	maxlength?: number;
	fullWidth?: boolean;
	type?: HTMLInputTypeAttribute;
	inputMode?: inputModeType;
	value?: number | string;

	id: string;
	name?: string;
	placeholder?: string;
	disabled?: boolean;
	readOnly?: boolean;
	invalid?: boolean;

	className?: string;
	onChange?: ChangeEventHandler<HTMLInputElement>;
	onClick?: () => void;
	onKeyDown?: () => void;
	onFocus?: () => void;
	onBlur?: FocusEventHandler<HTMLInputElement>;
	ariaDescribedBy?: string;
	nameplate?: string;
	autoFocus?: boolean;
	inputStyle?: INPUT_STYLES;
}

function hasMaxLength(type) {
	return ['text', 'email', 'search', 'password', 'tel', 'url'].includes(type);
}

export const Input = forwardRef<HTMLInputElement, PropsWithChildren<InputProps>>(
	(
		{
			autocomplete = 'off',
			maxlength = 255,
			fullWidth,
			type = 'text',
			inputMode = null,
			value,
			id,
			name = null,
			placeholder,
			disabled = false,
			readOnly,
			invalid = false,
			nameplate,
			autoFocus,

			className = null,
			onChange,
			onClick,
			onKeyDown,
			onFocus,
			onBlur,

			ariaDescribedBy = null,
			inputStyle = INPUT_STYLES.DEFAULT,
		},
		ref,
	) => {
		const inputRef = useRef<HTMLInputElement>();

		useEffect(() => {
			if (autoFocus) {
				setTimeout(() => {
					inputRef?.current?.focus();
				}, 0);
			}
		}, [autoFocus]);

		const handleRefs = useCallback(
			(inputEl: HTMLInputElement | null) => {
				if (ref && !isNull(inputEl)) {
					if (isCallable(ref)) {
						ref(inputEl);
					} else {
						ref.current = inputEl;
					}

					inputRef.current = inputEl;
				}
			},
			[ref, inputRef],
		);

		return (
			<div
				className={classNames(styles.wrapper, {
					[styles.appearanceBlue]: inputStyle === INPUT_STYLES.BLUE,
				})}
			>
				<input
					ref={handleRefs}
					autoComplete={autocomplete}
					maxLength={hasMaxLength(type) ? maxlength : null}
					type={type}
					inputMode={inputMode}
					defaultValue={value}
					placeholder={placeholder}
					className={classNames(className, styles.inputRoot, {
						[styles.stateInvalid]: invalid,
						[styles.stateFullWidth]: fullWidth === true,
					})}
					disabled={disabled}
					readOnly={readOnly}
					onChange={onChange}
					onClick={onClick}
					onKeyDown={onKeyDown}
					onFocus={onFocus}
					onBlur={onBlur}
					aria-describedby={ariaDescribedBy}
					aria-invalid={invalid}
					{...resolveIdAndName(id, name)}
				/>
				{nameplate && <div className={styles.nameplate}>{nameplate}</div>}
			</div>
		);
	},
);

Input.displayName = 'Input';
