import React from 'react';

import {Button} from '@/components/base/button/Button';
import {Dialog} from '@/components/base/dialog/Dialog';
import {ReadonlyLightbox} from '@/services/lightbox/interfaces';
import {typography} from '@/utilites/typography/typography';

import styles from './EmailRegisteredDialog.module.css';

interface EmailRegisteredDialogProps {
	title: string;
	description: string;
	buttonText: string;
	handleOpenCreateProjectDialog: () => void;
}

export class EmailRegisteredDialog extends ReadonlyLightbox<EmailRegisteredDialogProps> {
	onClick = () => {
		this.props.onClose();
		this.props.handleOpenCreateProjectDialog();
	};

	render() {
		const {title, description, buttonText, onClose} = this.props;

		return (
			<Dialog
				open
				onClose={onClose}
				className={styles.dialog}
				closeButtonClassName={styles.closeButton}
			>
				<div className={styles.root}>
					<div className={styles.info}>
						<div className={styles.title}>{typography(title)}</div>
						<div className={styles.desc}>{typography(description)}</div>
					</div>
					<div>
						<Button className={styles.button} onClick={this.onClick}>
							{buttonText}
						</Button>
					</div>
				</div>
			</Dialog>
		);
	}
}
