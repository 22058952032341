import {useCallback, useEffect, useMemo, useState} from 'react';

import {EmailRegisteredDialog} from '@/components/common/dialogs/email-registered-dialog/EmailRegisteredDialog';
import {ProjectHrefs} from '@/constants';
import {useAuthButtonActions} from '@/hooks/use-auth-button-actions';
import {useInjection} from '@/hooks/use-injection';
import {ILightboxService} from '@/services/lightbox/ILightboxService';
import {XrayEventId} from '@/services/metrics/constants';
import {IMetricsService} from '@/services/metrics/IMetricsService';

const title = 'Ваш личный электронный адрес зарегистрирован';
const description =
	'Он нужен для регистрации в VK WorkSpace и восстановления учётной записи в экстренных случаях.';
const buttonText = 'Продолжить регистрацию';
export function useEmailRegisteredLightbox(page: ProjectHrefs) {
	const {hasDomain, isAuth, featureService, handleOpenCreateProjectDialog} = useAuthButtonActions('main');
	const [LightboxService] = useInjection(ILightboxService);
	const [metricsService] = useInjection(IMetricsService);

	const isMatchPage = useMemo(() => {
		return page === ProjectHrefs.vkWorkSpace;
	}, [page]);

	const shouldActivateLightbox = isAuth && !hasDomain && isMatchPage;
	const [wasShown, setWasShown] = useState(false);

	const onContinueRegistrationClick = useCallback(() => {
		handleOpenCreateProjectDialog();
	}, [handleOpenCreateProjectDialog]);

	useEffect(() => {
		if (!shouldActivateLightbox) {
			return;
		}
		if (wasShown) {
			return;
		}

		metricsService.sendEvent({
			xrayEvents: [
				{
					name: XrayEventId.showPopupContinueRegistration,
				},
			],
		});

		LightboxService.openReadonly(EmailRegisteredDialog, {
			title,
			description,
			buttonText,
			handleOpenCreateProjectDialog: onContinueRegistrationClick,
		});
		setWasShown(true);
	}, [
		LightboxService,
		featureService.isServiceStarted,
		metricsService,
		onContinueRegistrationClick,
		shouldActivateLightbox,
		wasShown,
	]);
}
