import classNames from 'classnames';
import {observer} from 'mobx-react';
import {NextRouter, withRouter} from 'next/router';
import {Component, createRef, SyntheticEvent} from 'react';

import {Button, BUTTON_STYLES} from '@/components/base/button/Button';
import {Block} from '@/components/base/checkbox-or-radio/Block';
import {Checkbox} from '@/components/base/checkbox-or-radio/Checkbox';
import {DialogTitle} from '@/components/base/dialog/DialogTitle';
import {Input} from '@/components/base/input/Input';
import {Link} from '@/components/base/link/Link';
import {Typography, VARIANTS} from '@/components/base/typography/Typography';
import {BIZML_DOMAIN_SUFFIX} from '@/components/common/forms/create-project-form/const';
import {DomainType} from '@/components/common/forms/create-project-form/interfaces';
import {LINKS} from '@/constants';
import {injectProperty} from '@/container/injections';
import {SoonDeletedDomainCollection} from '@/mobx/business-collections/domain/SoonDeletedDomainCollection';
import {Feature} from '@/services/api/interfaces/me';
import {IFeatureService} from '@/services/feature/IFeatureService';
import {XrayEventId} from '@/services/metrics/constants';
import {IMetricsService} from '@/services/metrics/IMetricsService';
import {BooleanConverter} from '@/utilites/literals/BooleanConverter';
import {isBoolean, isString} from '@/utilites/type-guards/Primitives';

import {Additions} from '../additions/Additions';
import {getQuestions, getRecaptchaDisclaimer} from '../request-form/helpers';
import styles from './CreateProjectForm.module.css';
import {CreateProjectFormViewModel} from './CreateProjectFormViewModel';

enum FormElements {
	DomainType = 'domainType',
	OwnDomainName = 'ownDomainName',
	BizDomainName = 'bizDomainName',
	AgreeWithPolicy = 'agreeWithPolicy',
}

interface CreateProjectFormProps {
	onValidSubmit?: () => void;
	onClick?: () => void;
	onClose?: () => void;
	router: NextRouter;
}

interface CreateProjectFormState {
	selfHostedEnabled: boolean;
}

@observer
class CreateProjectForm extends Component<CreateProjectFormProps, CreateProjectFormState> {
	@injectProperty(IFeatureService) protected readonly featureService!: IFeatureService;
	@injectProperty(IMetricsService) protected readonly metricsService!: IMetricsService;
	protected viewModel: CreateProjectFormViewModel;

	protected ownDomainInputRef = createRef<HTMLInputElement>();
	protected bizDomainInputRef = createRef<HTMLInputElement>();

	constructor(props: CreateProjectFormProps) {
		super(props);
		this.viewModel = new CreateProjectFormViewModel({
			router: props.router,
			domainCollection: new SoonDeletedDomainCollection(),
		});

		this.viewModel.setValidations([
			[
				() => this.viewModel.ownDomainName,
				[
					(value, propName, viewModel) => {
						if (!viewModel.checkIfDomainTypeSelected(DomainType.OwnDomain)) {
							return;
						}
						const isValid = isString(value) && value !== '';
						return [isValid, 'Введите название домена'];
					},
				],
			],
			[
				() => this.viewModel.bizDomainName,
				[
					(value, propName, viewModel) => {
						if (!viewModel.checkIfDomainTypeSelected(DomainType.BizDomain)) {
							return;
						}
						const isValid = isString(value) && value !== '';
						return [isValid, 'Введите название домена'];
					},
				],
			],
			[
				() => this.viewModel.agreeWithPolicy,
				(value) => {
					return [
						isBoolean(value) && value === true,
						'Вы не подтвердили Условия использования сервиса и Политику конфиденциальности',
					];
				},
			],
		]);

		this.state = {
			selfHostedEnabled: false,
		};
	}

	async componentDidMount() {
		this.setFocusDomainInput(this.viewModel.domainType);

		const isTrialAvailable = await this.viewModel.checkIsTrialAvailable();
		const hasFeature = this.featureService.checkIfFeatureExist(Feature.DomainSelfhostedDisabled);
		const isSelfHostedEnabled = !hasFeature && isTrialAvailable;
		this.setState({
			selfHostedEnabled: isSelfHostedEnabled,
		});
		this.viewModel.setDomainType(isSelfHostedEnabled ? DomainType.BizDomain : DomainType.OwnDomain);
	}

	handleDomainTypeChange = () => {
		const value =
			this.viewModel.domainType === DomainType.OwnDomain ? DomainType.BizDomain : DomainType.OwnDomain;
		this.viewModel.setDomainType(value);
		this.setFocusDomainInput(value);
		this.metricsService.sendEvent({
			xrayEvents: [
				{
					name: XrayEventId.clickChangeDomainType,
					params: {
						domainType: value,
					},
				},
			],
		});
	};

	setFocusDomainInput(domainType: DomainType) {
		setTimeout(() => {
			switch (domainType) {
				case DomainType.OwnDomain:
					this.ownDomainInputRef.current?.focus();
					break;
				case DomainType.BizDomain:
					this.bizDomainInputRef.current?.focus();
					break;
			}
		}, 0);
	}

	handleFormSubmitMetricSend = (isFormValid: boolean) => {
		this.metricsService.sendEvent({
			xrayEvents: [
				{
					name: XrayEventId.clickButtonCompleteRegistration,
					params: {
						domain: this.viewModel.totalDomainName,
						status: (() => {
							switch (true) {
								case isFormValid:
									return 'invalid_form';
								case this.viewModel.isFormValid:
									return 'success';
								default:
									return 'error';
							}
						})(),
					},
				},
			],
		});
	};

	handleFormSubmit = async (event: SyntheticEvent) => {
		event.preventDefault();
		const isFormValid = this.viewModel.validate();

		if (isFormValid) {
			await this.viewModel.submitFormData();
		}

		this.handleFormSubmitMetricSend(isFormValid);

		// NOTE: вторая проверка на валидность нужна потому, что после отправки формы могут быть ошибки с бэкенда
		if (this.viewModel.isFormValid) {
			this.props.onValidSubmit();
		}
	};

	handleAnyChange = () => {
		this.viewModel.resetValidationResult();
	};

	renderErrorMessageFirst = (propGetter: () => any) => {
		const errorMessages = this.viewModel.getErrorMessages(propGetter);
		if (errorMessages?.length) {
			const errorMessage = errorMessages[0];
			return <div className={styles.fieldError}>{errorMessage}</div>;
		}
		return null;
	};

	// eslint-disable-next-line max-lines-per-function
	render() {
		const disabled = this.viewModel.inProgress;
		const agreeWithPolicyError = this.viewModel.getErrorMessageFirst(
			() => this.viewModel.agreeWithPolicy,
		);
		const isOwn = this.viewModel.domainType === DomainType.OwnDomain;

		return (
			<form onSubmit={this.handleFormSubmit} onChange={this.handleAnyChange} className={styles.form}>
				<DialogTitle>
					{isOwn ? 'Напишите домен компании' : 'Создайте домен и пользуйтесь бесплатно 30 дней'}
				</DialogTitle>
				{isOwn ? (
					<>
						<label htmlFor={`${FormElements.DomainType}-${DomainType.OwnDomain}`}>
							<Typography variant={VARIANTS.text} className={styles.label}>
								Нужно будет подтвердить, что домен принадлежит вам
							</Typography>
						</label>

						<div
							className={styles.inputWrapper}
							onClick={() => {
								this.viewModel.setDomainType(DomainType.OwnDomain);
								this.viewModel.resetValidationResult();
								this.setFocusDomainInput(DomainType.OwnDomain);
							}}
						>
							<Input
								id={FormElements.OwnDomainName}
								ref={this.ownDomainInputRef}
								placeholder="Введите домен"
								value={this.viewModel.ownDomainName}
								className={classNames(styles.control, styles.domainsControl, styles.input)}
								disabled={
									disabled ||
									this.viewModel.checkIfDomainInputDisabled(DomainType.OwnDomain)
								}
								onChange={(event) => this.viewModel.setOwnDomainName(event.target?.value)}
								invalid={
									!this.viewModel.checkIfFieldValid(() => this.viewModel.ownDomainName)
								}
							/>
							{this.renderErrorMessageFirst(() => this.viewModel.ownDomainName)}
						</div>
					</>
				) : (
					<>
						<label htmlFor={`${FormElements.DomainType}-${DomainType.BizDomain}`}>
							<Typography variant={VARIANTS.text} className={styles.label}>
								Домен — это часть вашего корпоративного адреса. Изменить придуманный домен
								позже не получится
							</Typography>
						</label>

						<div
							className={styles.inputWrapper}
							onClick={() => {
								this.viewModel.setDomainType(DomainType.BizDomain);
								this.viewModel.resetValidationResult();
								this.setFocusDomainInput(DomainType.BizDomain);
							}}
						>
							<Input
								id={FormElements.BizDomainName}
								ref={this.bizDomainInputRef}
								placeholder="Придумайте домен"
								value={this.viewModel.bizDomainName}
								className={classNames(
									styles.control,
									styles.domainsControl,
									styles.withNameplate,
									styles.input,
								)}
								disabled={
									disabled ||
									this.viewModel.checkIfDomainInputDisabled(DomainType.BizDomain)
								}
								onChange={(event) => this.viewModel.setBizDomainName(event.target?.value)}
								invalid={
									!this.viewModel.checkIfFieldValid(() => this.viewModel.bizDomainName)
								}
								nameplate={`.${BIZML_DOMAIN_SUFFIX}`}
							/>
							{this.renderErrorMessageFirst(() => this.viewModel.bizDomainName)}
						</div>
					</>
				)}
				<div className={styles.checkboxesWrapper}>
					<Block
						label={
							<label htmlFor={FormElements.AgreeWithPolicy}>
								<Typography variant={VARIANTS.listMini} className={styles.checkboxLabel}>
									Соглашаюсь c{' '}
									<Link
										href={LINKS.bizTermsofuse}
										target={'_blank'}
										className={styles.termsLink}
									>
										Условиями использования сервиса
									</Link>{' '}
									и с{' '}
									<Link
										href={LINKS.bizPrivacy}
										target={'_blank'}
										className={styles.termsLink}
									>
										Политикой конфиденциальности
									</Link>
								</Typography>
							</label>
						}
						errorMessage={
							agreeWithPolicyError && (
								<div className={styles.checkboxError}>{agreeWithPolicyError}</div>
							)
						}
					>
						<Checkbox
							id={FormElements.AgreeWithPolicy}
							name={FormElements.AgreeWithPolicy}
							value={BooleanConverter.booleanToString(!this.viewModel.agreeWithPolicy)}
							checked={this.viewModel.agreeWithPolicy}
							disabled={disabled}
							onChange={(event) =>
								this.viewModel.setAgreeWithPolicy(
									BooleanConverter.stringToBoolean(event.target?.value),
								)
							}
						/>
					</Block>
				</div>
				{
					<Additions
						feedbackQuestions={getQuestions()}
						disclaimer={getRecaptchaDisclaimer()}
						className={styles.additions}
					/>
				}
				<div className={styles.buttonWrapper}>
					<Button type="submit" onClick={this.props.onClick} disabled={disabled}>
						{isOwn ? 'Продолжить' : 'Создать'}
					</Button>
					<Button
						onClick={this.handleDomainTypeChange}
						disabled={disabled}
						style={BUTTON_STYLES.CONTRAST}
					>
						{isOwn ? 'Создать новый' : 'У меня есть домен'}
					</Button>
				</div>
			</form>
		);
	}
}
export default withRouter<CreateProjectFormProps>(CreateProjectForm);
