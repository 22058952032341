import classNames from 'classnames';
import React, {KeyboardEvent, PropsWithChildren, useCallback, useEffect, useRef} from 'react';

import styles from '@/components/base/dialog/Dialog.module.css';
import {DialogBody} from '@/components/base/dialog/DialogBody';
import {DialogDescription} from '@/components/base/dialog/DialogDescription';
import {DialogTitle} from '@/components/base/dialog/DialogTitle';
import {PureDialog, PureDialogProps} from '@/components/base/dialog/PureDialog';
import {PortalContainer} from '@/components/helpers/portal/interfaces';
import {getContainer} from '@/components/helpers/portal/Portal';
import {useFocusTrack} from '@/hooks/use-focus-track';
import CrossIcon from '@/icons/cross.svg';
import {isEscape} from '@/utilites/event-handler/is-escape';

import {DialogWrap} from './DialogWrap';

export const DIALOG_IDENTIFIER = Symbol.for('$$VK_WS_Landings_Dialog');

export const isDialog = (element: React.ReactElement): boolean => {
	return element.props.identifier === DIALOG_IDENTIFIER;
};

export interface DialogProps extends PureDialogProps {
	/**
	 * Управление фокусом.
	 * Запоминает элемент, который был в фокусе при открытии диалогового окна,
	 * перемещает фокус на диалоговое окно, и после закрытия диалогового окна
	 * возвращает фокус на элемент, который был в фокусе
	 */
	autoFocus?: boolean;
	backdrop?: boolean;
	disableBackdropScroll?: boolean;
	disableBackdropClick?: boolean;
	focusTrack?: boolean;
	/**
	 * Идентификатор диалогового окна. Используется для связи диалогового окна
	 * и заголовка
	 */
	id?: string;
	/**
	 * Элемент, в который будет нарисовано диалоговое окно
	 */
	container?: PortalContainer;
	/**
	 * Функция, которая будет выполнена перед закрытием окна
	 */
	onClose?: (event: KeyboardEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement> | MouseEvent) => void;

	/**
	 * Показывать крестик
	 */
	withCloseButton?: boolean;

	open?: boolean;

	identifier?: symbol;

	maxWidth?: number | string;

	className?: string;

	closeButtonClassName?: string;
}

// eslint-disable-next-line sonarjs/cognitive-complexity, max-lines-per-function
export function Dialog({
	children,
	container,
	autoFocus = true,
	focusTrack = false,
	open,
	onClose,
	backdrop = true,
	disableBackdropClick = false,
	disableBackdropScroll = true,
	withCloseButton = true,
	className,
	closeButtonClassName,
	...dialogProps
}: PropsWithChildren<DialogProps>) {
	const dialogRef = useRef(null);
	const wrapRef = useRef(null);

	const handleKeyDown = useCallback(
		function (event) {
			if (isEscape(event) === false) {
				return;
			}

			event.stopPropagation();

			if (typeof onClose === 'function') {
				onClose(event);
			}
		},
		[onClose],
	);
	const handleWrapMouseDown = useCallback(
		function (event) {
			const {current: wrapEl} = wrapRef;

			if (disableBackdropClick) {
				return;
			}

			if (wrapEl === null || event.target !== wrapEl) {
				return;
			}

			if (typeof onClose === 'function') {
				onClose(event);
			}
		},
		[wrapRef, disableBackdropClick, onClose],
	);

	const handleContainerMouseDown = useCallback(
		function (event?: MouseEvent) {
			if (disableBackdropClick) {
				return;
			}
			if (dialogRef.current === event?.target || dialogRef.current.contains(event?.target)) {
				return;
			}
			if (typeof onClose === 'function') {
				onClose(event);
			}
		},
		[onClose, disableBackdropClick],
	);

	useFocusTrack(open && focusTrack);

	const focusDialog = useCallback(
		function () {
			if (dialogRef.current !== null && autoFocus === true && open === true) {
				dialogRef.current.focus();
			}
		},
		[autoFocus, open],
	);

	useEffect(
		function () {
			focusDialog();
		},
		[focusDialog],
	);

	if (open !== true) {
		return null;
	}

	const actualContainer = container || getContainer(container);
	const actualHandleWrapMouseDown = disableBackdropScroll ? handleWrapMouseDown : undefined;
	const actualHandleContainerMouseDown = !disableBackdropScroll ? handleContainerMouseDown : undefined;

	return (
		<DialogWrap
			elRef={wrapRef}
			container={actualContainer}
			onKeyDown={handleKeyDown}
			onWrapMouseDown={actualHandleWrapMouseDown}
			onContainerMouseDown={actualHandleContainerMouseDown}
			backdrop={backdrop}
			disableBackdropScroll={disableBackdropScroll}
		>
			<PureDialog {...dialogProps} ref={dialogRef} open={open} className={className}>
				{withCloseButton && (
					<div onClick={onClose} className={classNames(styles.close, closeButtonClassName)}>
						<CrossIcon width={28} height={28} />
					</div>
				)}
				{children}
			</PureDialog>
		</DialogWrap>
	);
}

Dialog.displayName = 'Dialog';

// Нужно удалить, но выше есть проверка isDialog - element.props.identifier === DIALOG_IDENTIFIER
// пока не понятно как обойтись без defaultProps
Dialog.defaultProps = {
	identifier: DIALOG_IDENTIFIER,
};

Dialog.Body = DialogBody;
Dialog.Title = DialogTitle;
Dialog.Description = DialogDescription;
