import {CheckboxType} from '@/components/base/checkbox-or-radio/interfaces';

import {isIndeterminate} from './is-indeterminate';

/**
 * @param {Object} props
 * @param {string} props.type
 * @param {boolean} props.indeterminate
 * @param {boolean} props.checked
 * @returns {boolean}
 */
export function isChecked({
	type,
	indeterminate: _indeterminate,
	checked,
}: {type?: CheckboxType; indeterminate?: boolean; checked?: boolean} = {}) {
	const indeterminate = isIndeterminate({
		type,
		indeterminate: _indeterminate,
	});

	return indeterminate === false && checked === true;
}
